<template>
    <div>
        <div class="col-md-12">
            <div class="card border-0 rounded shadow">
                <div class="card-body">
                    <h5 class="card-title">Reagendar consulta</h5>
                    <hr>
                    <Times v-if="schedule != null"
                           :id_prof="schedule.id_prof"
                           :id_place="schedule.id_place"
                           :id_spec="schedule.id_spec"
                           :id_city="schedule.sched_online ? null : schedule.id_city"
                           :id_insurance="schedule.id_insurance"
                           :id_paymeth="schedule.id_paymeth"
                           :id_sched_father="schedule.id_sched"
                           @onClickDate="reSchedule"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Times from "../components/Times";
import http from "@/helpers/http";
import Alert from "@/helpers/alert";
import Swal from "sweetalert2";

export default {
    name: "ReSchedule",
    components: {
        Times,
    },
    data() {
        return {
            state: 'loading',
            token: null,
            scheduleId: null,
            userId: null,
            schedule: null,
        }
    },
    created() {
        let params = atob(this.$route.query.params)
        let [token, userId, scheduleId] = params.split('@');
        this.token = token;
        this.userId = userId;
        this.scheduleId = scheduleId;
        this.authUsingToken();
        this.findSchedule();
    },
    methods: {
        authUsingToken() {
            this.$store.dispatch('auth/loginUsingToken', this.token)
                .finally(() => {
                    this.state = 'loaded';
                });
        },
        findSchedule() {
            return http.get(`/users/${this.userId}/schedules/${this.scheduleId}`)
                .then((response) => {
                    this.schedule = response.data;
                    return response;
                });
        },
        reSchedule(availabilityTime) {
            let param = {
                sched_date: availabilityTime.available_date,
                sched_time: availabilityTime.available_time,
                sched_datetime_patient: availabilityTime.sched_datetime_patient,
            };

            http.post(`/users/${this.userId}/schedules/${this.scheduleId}/reschedule`, param)
                .then(() => {
                    Swal.fire({
                        title: 'Aeeeh!',
                        text: 'Reagendamento realizado com sucesso.',
                        icon: 'success',
                        allowOutsideClick: false,
                        confirmButtonText: 'Acessar minha conta',
                        confirmButtonColor: '#780f5a',
                    }).then(() => {
                        this.$router.push('/schedules')
                    });
                })
                .catch((e) => Alert.error(e.response.data));
        }
    }
}
</script>

<style scoped>

</style>