<template>
    <JitsiMeeting v-if="this.waitingRoom"
        :roomName="this.waitingRoom.wr_code"
        :domain="this.waitingRoom.wr_domain"
        :configOverwrite="{
            subject: 'Teleconsulta',
            hideConferenceSubject: false,
            disableProfile: true,
            startWithVideoMuted: false,
            hideConferenceTimer: true,
            defaultLocalDisplayName: 'Você',
            toolbarButtons: [
                'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                'hangup','shareaudio', 'sharedvideo', 'toggle-camera', 'stats',
                'profile', 'etherpad', 'settings', 'raisehand', 'videoquality',
                'filmstrip', 'select-background', 'tileview', 'participants-pane',
                'noisesuppression',
            ],
        }"
        :interfaceConfigOverwrite="{
            APP_NAME: 'Ubbimed',
            MOBILE_APP_PROMO: false,
            SHOW_JITSI_WATERMARK: false,
            DEFAULT_BACKGROUND: '#780f5a',
            HIDE_INVITE_MORE_HEADER: true,
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            SETTINGS_SECTIONS: ['devices'],
            LANG_DETECTION: false,
        }"
        lang="ptBR"
        width="100%"
        :height=this.height
        :userInfo="{
            displayName: this.userAuth.user_name,
            email: this.userAuth.user_email,
        }"
        @onApiReady="(externalApi) => handleApiReady(externalApi)"
        @onReadyToClose="handleReadyToClose"
        @getIframeRefOnApiReady="handleJitsiIFrameRef1"
    />
</template>

<script>
import http from "../../helpers/http";
import Swal from "sweetalert2";
import { JitsiMeeting } from "@jitsi/vue-sdk";

export default {
    name: "PatientScheduleAttendance",
    components: {
        JitsiMeeting
    },
    data() {
        return {
            schedule: null,
            queue: null,
            waitingRoom: null,
            scheduleId: null,
            schedToken: null,
            userToken: null,
            height: null,
            jistiReference: null,
        }
    },
    computed: {
        userAuth() {
            return this.$store.state.auth.user;
        },
    },
    created() {
        this.scheduleId = this.$route.params.scheduleId;
        this.schedToken = this.$route.query.sched_token;
        this.userToken = this.$route.query.user_token;
    },
    mounted() {
        this.height = window.innerHeight
        this.findSchedule().then(() => this.wsConnect());
    },
    beforeUnmount() {
        Echo.leave(`attendances.${this.queue.id_queue}`);
    },
    methods: {
        findSchedule() {
            let schedUrl = `/users/${this.userAuth?.id_user}/schedules/${this.scheduleId}`;
            if (this.schedToken && this.userToken) {
                schedUrl = `/schedule/attendance-token?token=${this.schedToken}&user_token=${this.userToken}`;
            }

            return http.get(schedUrl).then(async (response) => {
                this.schedule = response.data;
                if (this.schedule.sched_online_call_finished) {
                    this.$router.push('/schedules');
                    return;
                }

                this.queue = this.schedule.queue;
                this.waitingRoom = this.queue?.waiting_room;

                return response;
            }).catch((e) => {
                let title = 'Falha ao carregar o agendamento.';
                if (e.response.status === 422) {
                    title = e.response.data;
                }

                Swal.fire({
                    text: title,
                    title: 'Opsss',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'OK, Acessar a Ubbimed',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    }
                }).then(() => {
                    this.$router.push('/schedules');
                });
            });
        },
        wsConnect() {
            Echo.private(`attendances.${this.queue.id_queue}`)
                .listen('ScheduleStarted', () => window.location.reload())
                .listen('ScheduleCanceled', () => this.$router.push('/schedules'))
                .listen('ScheduleFinished', () => this.$router.push('/schedules'));
        },
        handleApiReady(jistiReference) {
            this.jistiReference = jistiReference;
            // adicionar listen nos eventos do jitsi
        },
        handleReadyToClose() {
            // chamar a api
            this.$router.push('/schedules');
        },
        handleJitsiIFrameRef1(iframeRef) {
            // customizar o iframe do jitsi
        },
    }
}
</script>

<style scoped>
#jitsiConferenceFrame0 {
    overflow: hidden;
}
</style>