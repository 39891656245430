<template>
  <section class="section-two bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-md-3">

        <div class="col-lg-3 mb-4 order-2 order-sm-1 order-md-1">
          <MenuAction/>
        </div>

        <div class="col-lg-9 mb-4 order-1 order-sm-2 order-md-2">

          <div v-if="statePage === 'init' || statePage === 'loading'">
            <div class="card shadow rounded border-0 mb-3">
              <div class="row">
                <div class="text-center">
                  <div class="card-body">
                    <div class="content">
                      <img src="@/assets/images/loading.gif" :class="isMobile() ? 'w-75' : 'w-25'" alt="Carregando">
                      <p class="h3">Carrengando informações</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="statePage === 'error'">
            <div class="card shadow rounded border-0 mb-3">
              <div class="row">
                <div class="text-center">
                  <div class="card-body">
                    <div class="content">
                      <img src="@/assets/images/animation_error.gif" :class="isMobile() ? 'w-75' : 'w-25'" alt="Error">
                      <p class="h3 text-muted">Opss... não foi possível carregar os profissionais</p>
                      <button onclick="window.location.reload()" class="btn btn-primary">Tentar novamente</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
              <p class="h4">
                  <b>Escolha o profissional, dia e hora</b>
              </p>

            <div class="mb-4">
              <div class="form-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-search fea icon-sm icons">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input name="profissional" id="profissional" class="form-control ps-5"
                       placeholder="Pesquisar profissional" v-model="search">
              </div>
            </div>

            <div class="card shadow rounded border-0 mb-3"
                 :class="isMobile() ? '' : 'pricing-rates business-rate'"
                 v-for="professional in professionals" :id="'prof-'+professional.id_prof+'-'+professional.id_place">

              <div class="row">
                <div class="col-md-4">
                  <div class="card-body">
                    <div class="content">
                      <div class="row">
                        <div class="col-4 text-center">
                          <img :src="url(professional.photo)" v-if="professional.photo"
                               class="avatar avatar-md-md shadow-md rounded-circle" :alt="professional.prof_name">
                          <img src="../../assets/images/no-photo.png" v-else
                               class="avatar avatar-md-md shadow-md rounded-circle" :alt="professional.prof_name">
                        </div>
                        <div class="col-8">
                          <router-link class="h5"
                              :to="`/professional/${professional.id_prof}?city=${professional.id_city}&place=${professional.id_place}`">
                            {{professional.prof_name}}
                          </router-link>

                          <div class="">
                            <p class="text-muted">
                              <b>
                                {{specialty.spec_name.toUpperCase()}}
                                <a href="#" class="mt-1" v-if="professional.specialties_count > 1"
                                   data-bs-toggle="modal" data-bs-target="#ModalSpecialties"
                                   title="Ver todas"
                                   @click.prevent="getAllSpecialtiesFromProfessional(professional)">
                                  <span class="badge bg-primary">
                                    <i  class="fas fa-angle-down"></i>
                                  </span>
                                </a>
                              </b>
                              <br>
                              <b>{{professional.kind_acronym}}</b> {{professional.prof_council}} <br><br>
                            </p>
                          </div>
                        </div>
                      </div>

                      <p>
                        <b>Endereço:</b> <br>
                        <span v-if="professional.show_address && professional.place_address">
                          {{professional.place_address}}, {{professional.place_addr_numer}}<br>
                          {{professional.place_neighborhood}},
                        </span>
                        <span v-if="professional.city_name && professional.state_initials">
                          {{professional.city_name}}/{{professional.state_initials}}
                        </span>
                      </p>

                      <p v-if="professional.min_patient_age != null || professional.max_patient_age != null">
                        <b>Faixa etária:</b> <br>
                          {{professional.patient_age_desc}}
                      </p>

                    </div>
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="table-responsive mt-3 pe-4">
                    <table class="table table-sm table-borderless">
                      <tbody>

                      <tr>
                        <th v-if="hideBtnPreviousDatesProf.includes(professional.id_prof)"></th>
                        <th v-else scope="col" class="text-start" @click="previousPageProf(professional);" style="cursor: pointer;">
                          <i class="fas fa-chevron-left"></i>
                        </th>

                        <th scope="col" class="text-center" v-for="availabilityDate in professional.availability_dates">
                          <small>
                            {{availabilityDate.name_available_date_patient}} <br>
                            <b>
                              {{ date(availabilityDate.available_date_patient).format('DD') }}/{{availabilityDate.name_month_available_date_patient}}
                            </b>
                          </small>
                        </th>

                        <th v-if="hideBtnNextDatesProf.includes(professional.id_prof)"></th>
                        <th v-else-if="professional.availability_dates.length > 0"
                            scope="col" class="text-end" @click="nextPageProf(professional);" style="cursor: pointer;">
                          <i class="fas fa-chevron-right"></i>
                        </th>
                      </tr>

                      <tr v-if="professional.availability_dates?.length <= 0">
                        <td colspan="12">
                          <img :src="require('@/assets/images/save_to_bookmarks.svg')"
                               alt="Doctor" class="rounded img-fluid mx-auto d-block w-25">
                          <br>
                          <p class="text-center text-muted para-desc mx-auto">
                            <span class="text-dark">
                              Nenhum horário disponível.
                            </span>
                            <br>
                          </p>
                        </td>
                      </tr>

                      <tr v-else v-for="indexRow in professional.availability_times_max_count"
                          class="text-center"
                          :class="professional.availability_times_max_count > maxTimes && indexRow > maxTimes && !expandedTimesProf.includes(professional.id_prof) ? 'd-none' : ''">
                        <th></th>
                        <td v-for="availabilityDate in professional.availability_dates">
                          <button @click="selectAvailability(professional, availabilityDate.availability_times[indexRow-1])"
                                  class="btn btn-sm btn-pills btn-outline-primary"
                                  v-if="availabilityDate.availability_times[indexRow-1]">
                            <b>{{availabilityDate.availability_times[indexRow-1].available_time_patient}}</b>
                          </button>
                        </td>
                        <th></th>
                      </tr>

                      </tbody>
                    </table>
                  </div>

                  <div class="text-center" v-if="professional.availability_times_max_count > maxTimes">
                    <button v-if="expandedTimesProf.includes(professional.id_prof)" class="btn btn-link"
                       @click="toggleExpandedTimesProf(professional)">
                      Mostrar menos
                      <i class="fas fa-angle-double-up"></i>
                    </button>
                    <button v-else-if="professional.availability_dates.length > 0"
                            @click="toggleExpandedTimesProf(professional)" class="btn btn-link">
                      Mostrar todos
                      <i class="fas fa-angle-double-down"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card shadow rounded border-0 mb-3 p-4 text-center" v-if="professionals.length === 0">
              <img :src="require('@/assets/images/no_datal.svg')"
                   alt="Foto" class="w-25 align-self-center"/>

              Opss... Nenhum profissional encontrado.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="ModalSpecialties" tabindex="-1" aria-labelledby="ModalSpecialties" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded shadow border-0">
          <div class="modal-header border-bottom">
            <h5 class="modal-title">Especialidades</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="bg-white p-3 rounded box-shadow">
              <ul class="list-unstyled text-muted">

                <li class="mb-0" v-for="specialty in specialtiesFromProfessional">
                  <span class="text-primary h5 me-2">
                    <i class="uil uil-check-circle align-middle"></i>
                  </span> {{ specialty.spec_name }}
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import MenuAction from './MenuAction.vue'
import http from "../../helpers/http";
import date from "../../helpers/date";
import config from "../../config";
import Device from "../../helpers/device";
import str from "../../helpers/str";

export default {
  name: 'Professionals',
  components: {
    MenuAction
  },
  data() {
    return {
      statePage: 'init', // init, loading, error, loaded
      search: '',
      specialtiesFromProfessional: [],
      currentProfessional: null,
      expandedTimesProf: [],
      hideBtnNextDatesProf: [],
      hideBtnPreviousDatesProf: [],
      startEndDays: this.isMobile() ? 3 : 7,
      timezone: config.timezone,
      maxTimes: 5
    }
  },
  computed: {
    isExam() {
      return this.$store.state.toSchedule.attendanceType === 'EXAM';
    },
    schedule() {
      return this.$store.state.toSchedule.schedule;
    },
    specialty() {
      return this.$store.state.toSchedule.schedule.specialty;
    },
    professionals() {
      let professionals = this.$store.state.toSchedule.professionals;
      if (this.search === '')
        return professionals;

      return professionals.filter(professional => {
        let strFilter = JSON.stringify(professional);
        return strFilter.toLowerCase().includes(this.search.toLowerCase())
      });
    },
  },
  mounted() {
    this.statePage = 'loading'
    this.$store.dispatch('toSchedule/getProfessionals', {start_end_days: this.startEndDays}).then((response) => {
      response.data.forEach((professional) => {
        this.hideBtnPreviousDatesProf.push(professional.id_prof);
      });

      this.statePage = 'loaded'
    }).catch(() => {
      this.statePage = 'error';
    });
  },
  methods: {
    url(path) {
        return str.url(path)
    },
    date: date,
    config: config,
    isMobile() {
      return Device.isMobile();
    },
    previousPageProf(professional) {
      let availabilityDates = professional.availability_dates;
      let startAvailability = availabilityDates[0];
      let startDate = date(startAvailability.available_date_patient).add(-1, 'day').format('YYYY-MM-DD');

      this.$store.dispatch('toSchedule/paginatePreviousAvailabilityProfessional', {
        professional: professional,
        start_date: startDate,
        start_end_days: this.startEndDays,
      }).then((response) => {
        let availability = response.data;
        let firstDate = availability[0].available_date_patient;
        if (date(firstDate) > date(startDate)) {
          this.hideBtnPreviousDatesProf.push(professional.id_prof);
        } else {
          let index = this.hideBtnPreviousDatesProf.indexOf(professional.id_prof);
          if (index >= 0)
            this.hideBtnPreviousDatesProf.splice(index, 1);

          let indexNext = this.hideBtnNextDatesProf.indexOf(professional.id_prof);
          if (indexNext >= 0)
            this.hideBtnNextDatesProf.splice(indexNext, 1);
        }
      });
    },
    nextPageProf(professional) {
      let availabilityDates = professional.availability_dates;

      let startAvailability = availabilityDates[1];
      if (!startAvailability) {
        this.hideBtnNextDatesProf.push(professional.id_prof);
        return;
      }

      let startDate = startAvailability.available_date_patient;

      this.$store.dispatch('toSchedule/paginateNextAvailabilityProfessional', {
        professional: professional,
        start_date: startDate,
        start_end_days: this.startEndDays,
      }).then((response) => {
        let availability = response.data;
        if (availability.length < this.startEndDays) {
          this.hideBtnNextDatesProf.push(professional.id_prof);
        } else {
          let index = this.hideBtnNextDatesProf.indexOf(professional.id_prof);
          if (index >= 0)
            this.hideBtnNextDatesProf.splice(index, 1);

          let indexPrevious = this.hideBtnPreviousDatesProf.indexOf(professional.id_prof);
          if (indexPrevious >= 0)
            this.hideBtnPreviousDatesProf.splice(indexPrevious, 1);
        }
      });
    },
    toggleExpandedTimesProf(professional) {
      if (this.expandedTimesProf.includes(professional.id_prof)) {
        let index = this.expandedTimesProf.indexOf(professional.id_prof);
        this.expandedTimesProf.splice(index, 1);
      } else {
        this.expandedTimesProf.push(professional.id_prof);
      }
      document.getElementById('prof-'+professional.id_prof+'-'+professional.id_place).scrollIntoView();
    },
    selectAvailability(prof, availabilityTime) {
      let professional = {...prof};
      delete professional.availability_dates;
      this.$store.commit('toSchedule/selectAvailability', {professional, availabilityTime});
      if (this.$store.getters['auth/isAuth']) {
        this.$router.push('/schedule/patients');
      } else {
        this.$router.push('/schedule/pre-signin');
      }
    },
    getAllSpecialtiesFromProfessional(professional) {
      http.get(`/professionals/${professional.id_prof}/specialties`).then((specialties) => this.specialtiesFromProfessional = specialties.data);
    },
  }
}
</script>

<style scoped>

</style>
