<template>
  <section class="section bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-md-3">
        <div class="card shadow rounded border-0 mb-3">

          <div v-if="statePage === 'init' || statePage === 'loading'">
            <div class="card shadow rounded border-0 mb-3">
              <div class="row">
                <div class="text-center">
                  <div class="card-body">
                    <div class="content">
                      <img src="@/assets/images/loading.gif" :class="isMobile() ? 'w-75' : 'w-25'" alt="Carregando">
                      <p class="h3">Carrengando informações</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="statePage === 'error'">
            <div class="card shadow rounded border-0 mt-3 mb-3 text-center">
              <div class="row">
                <div class="text-center">
                  <div class="card-body">
                    <div class="content">
                      <img src="@/assets/images/animation_error.gif" :class="isMobile() ? 'w-75' : 'w-25'" alt="Error">
                      <p class="h3 text-muted">Opss... não foi possível carregar os profissionais</p>
                      <button onclick="window.location.reload()" class="btn btn-primary">Tentar novamente</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>

            <div class="row" v-if="professional">
              <div class="col-md-5 mb-6 mt-4">

                <div class="row">
                  <div class="col-md-4">
                    <div class="text-center mt-lg-5">
                      <img :src="url(professional.photo)" v-if="professional.photo"
                           class="avatar avatar-md-md shadow-md rounded-circle" :alt="professional.prof_name">
                      <img src="../../../assets/images/no-photo.png" v-else
                           class="avatar avatar-md-md shadow-md rounded-circle" :alt="professional.prof_name">
                    </div>
                  </div>
                  <div class="col-md-8">
                    <p>
                      <span class="text-primary text-lg-start text-center">
                        <b>{{professional.prof_name}}</b>
                      </span> <br>
                      <b>{{professional.kind_acronym}}</b> {{professional.prof_council}}
                    </p>
                    <div>
                      <label class="form-label" for="specId">Especialidades</label>
                      <select class="form-control" v-model="specId" id="specId" @change="loadAvailabilityProfessional">
                        <option v-for="specialty in specialties" :value="specialty.id_spec">
                          {{ specialty.spec_name }}
                        </option>
                      </select>
                    </div>

                    <div class="row mt-4">
                      <div class="col-6 text-center">
                        <div class="d-grid gap-2">
                          <button type="button" @click="toggleScheduleOnline(true)" :class="scheduleOnline ? 'btn-primary' : 'btn-outline-primary'"
                                  class="btn btn-sm fs-6">
                            Agendar <br>Teleconsulta
                          </button>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="d-grid gap-2">
                          <button type="button" @click="toggleScheduleOnline(false)" :class="scheduleOnline ? 'btn-outline-primary' : 'btn-primary'"
                                  class="btn btn-sm fs-6">
                            Agendar <br>Presencial
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-12 ms-lg-5">
                    <address v-if="professional.place_address && professional.show_address">
                      <b>Endereço:</b> <br>
                      <span>
                        {{professional.place_address}}, {{professional.place_addr_numer}},
                          {{professional.place_neighborhood}}, {{professional.city_name}}/{{professional.state_initials}}
                      </span>
                    </address>

                    <div v-if="professional.show_phone">
                      <p v-if="professional.prof_phone || professional.place_phone">
                        <b>Telefone:</b> <br>
                        <a :href="'tel:' + professional.prof_phone">
                          <span>
                            {{professional.prof_phone}}
                          </span>
                        </a>
                        <br>
                        <a :href="'tel:' + professional.place_phone">
                          <span class="text-muted"
                                v-if="professional.prof_phone != professional.place_phone">
                            {{professional.place_phone}}
                          </span>
                        </a>
                      </p>
                    </div>

                    <p>
                      <b>Faixa etária:</b> <br>
                      {{professional.patient_age_desc}}
                    </p>

                  </div>
                </div>

              </div>

              <div class="col-md-7 mb-6 mt-4">
                <p class="text-center">Horários disponíveis</p>
                <div class="table-responsive mt-3 pe-4">
                  <table class="table table-sm table-borderless">
                    <tbody>

                    <tr>
                      <th v-if="!hideBtnPreviousDatesProf" scope="col" class="text-start" @click="previousPageProf();"
                          style="cursor: pointer;">
                        <i class="fas fa-chevron-left"></i>
                      </th>
                      <th v-else></th>

                      <th scope="col" class="text-center" v-for="availabilityDate in professional?.availability_dates">
                        <small>
                          {{ availabilityDate.name_available_date_patient }} <br>
                          <b>
                            {{
                              date(availabilityDate.available_date_patient).format('DD')
                            }}/{{ availabilityDate.name_month_available_date_patient }}
                          </b>
                        </small>
                      </th>

                      <th v-if="hideBtnNextDatesProf"></th>
                      <th v-else-if="professional?.availability_dates?.length > 0"
                          scope="col" class="text-end" @click="nextPageProf();"
                          style="cursor: pointer;">
                        <i class="fas fa-chevron-right"></i>
                      </th>
                    </tr>

                    <tr v-if="!professional?.availability_dates">
                      <td colspan="12">
                        <img :src="require('@/assets/images/save_to_bookmarks.svg')"
                             alt="Doctor" class="rounded img-fluid mx-auto d-block w-25">
                        <br>
                        <p class="text-center text-muted para-desc mx-auto">
                          <span class="text-dark">
                            Nenhum horário disponível.
                          </span>
                          <br>
                        </p>
                      </td>
                    </tr>

                    <tr v-else v-for="indexRow in (quantityRows = getQuantityRows())"
                        class="text-center"
                        :class="quantityRows > quantityRowsAllowed && indexRow > quantityRowsAllowed && !expandedTimesProf ? 'd-none' : ''">
                      <th></th>
                      <td v-for="availabilityDate in professional.availability_dates">
                        <button @click="selectAvailability(availabilityDate.availability_times[indexRow-1])"
                                class="btn btn-sm btn-pills btn-outline-primary"
                                v-if="availabilityDate.availability_times[indexRow-1]">
                          <b>{{ availabilityDate.availability_times[indexRow - 1].available_time_patient }}</b>
                        </button>
                      </td>
                      <th></th>
                    </tr>

                    </tbody>
                  </table>
                </div>
                <div class="text-center" v-if="quantityRows > 4">
                  <button v-if="expandedTimesProf" class="btn btn-link"
                          @click="toggleExpandedTimesProf()">
                    Mostrar menos
                    <i class="fas fa-angle-double-up"></i>
                  </button>
                  <button v-else-if="professional.availability_dates?.length > 0"
                          @click="toggleExpandedTimesProf()" class="btn btn-link">
                    Mostrar todos
                    <i class="fas fa-angle-double-down"></i>
                  </button>
                </div>
              </div>
            </div>

            <div v-else>
              <p class="m-5 text-center"> Nenhuma informação encontrada.</p>
            </div>
          </div>
        </div>

      </div>
    </div>

  </section>
</template>

<script>
import http from "../../../helpers/http";
import date from "../../../helpers/date";
import Device from "../../../helpers/device";
import str from "../../../helpers/str";

export default {
  name: "ProfessionalIndex",
  data() {
    return {
      statePage: 'init', // init, loading, error, loaded
      professional: null,
      professionalid: null,
      cityId: null,
      placeId: null,
      specId: null,
      quantityRows: null,
      specialties: [],
      expandedTimesProf: false,
      hideBtnPreviousDatesProf: true,
      hideBtnNextDatesProf: false,
      startEndDays: this.isMobile() ? 3 : 7,
      quantityRowsAllowed: this.isMobile() ? 4 : 8,
      scheduleOnline: false,
    }
  },
  mounted() {
    this.statePage = 'loading'
    this.professionalid = this.$route.params.professionalId;
    this.cityId = this.$route.query.city;
    this.placeId = this.$route.query.place;
    this.getSpecialties().then(() => {
      this.specId = this.$route.query.specialty ?? this.specialties[0].id_spec;
      this.findProfessional();
    }).then(() => {
      this.statePage = 'loaded'
    }).catch(() => {
      this.statePage = 'error';
    });
  },
  methods: {
    date: date,
      url(path) {
          return str.url(path)
      },
    isMobile() {
      return Device.isMobile();
    },
    toggleScheduleOnline(isOnline) {
      this.scheduleOnline = isOnline;
      this.loadAvailabilityProfessional();
    },
    findProfessional() {
      let params = {
        start_end_days: this.startEndDays,
        id_prof: this.professionalid,
        id_place: this.placeId,
        id_spec: this.specId,
        id_city: this.scheduleOnline ? null : this.cityId,
      };

      return http.get(`/schedule/professionals/${this.professionalid}`, {params: params}).then((response) => {
        this.professional = response.data;
        if (this.professional.default_release_time_for === 'O') {
          this.scheduleOnline = true;
        } else if (this.professional.default_release_time_for === 'F') {
          this.scheduleOnline = false;
        }

        this.loadAvailabilityProfessional();
      });
    },
    loadAvailabilityProfessional() {
      let params = {
        with_availability: true,
        start_end_days: this.startEndDays,
        id_prof: this.professionalid,
        id_place: this.placeId,
        id_city: this.scheduleOnline ? null : this.cityId,
        id_spec: this.specId,
      };

      http.get(`/schedule/professionals/${this.professionalid}`, {params: params}).then((response) => {
        this.professional.availability_dates = response.data.availability_dates;
      });
    },
    toggleExpandedTimesProf() {
      this.expandedTimesProf = !this.expandedTimesProf;
    },
    getQuantityRows() {
      let quantityRows = 0;
      this.professional.availability_dates?.forEach(function (availabilityDate) {
        let quantityTimes = availabilityDate.availability_times.length
        if (quantityTimes > quantityRows)
          quantityRows = quantityTimes;
      });

      return quantityRows;
    },
    findCity() {
      return http.get(`/cities/${this.cityId}`);
    },
    async selectAvailability(availabilityTime) {
      this.$store.commit('toSchedule/setScheduleType', 'PROFESSIONAL');

      if (!this.scheduleOnline && this.cityId) {
        let responseCity = await this.findCity();
        this.$store.commit('toSchedule/selectCity', responseCity.data);
      } else {
        this.$store.commit('toSchedule/selectCity', null);
      }

      let specialty = this.specialties.find(spec => spec.id_spec === this.specId);
      this.$store.commit('toSchedule/selectSpecialty', specialty);

      let professional = {...this.professional};
      this.$store.commit('toSchedule/selectAvailability', {professional, availabilityTime});

      this.$router.push(`/professional/${professional.id_prof}/payment-methods`);
    },
    nextPageProf() {
      let professional = this.professional;
      let availabilityDates = professional.availability_dates;

      let startAvailability = availabilityDates[1];
      if (!startAvailability) {
        this.hideBtnNextDatesProf = true;
        return;
      }

      let startDate = startAvailability.available_date_patient;
      http.get('/schedule/availabilities/dates-times', {
        params: {
          id_place: professional.id_place,
          id_prof: professional.id_prof,
          id_spec: this.specId,
          id_city: this.scheduleOnline ? null : this.cityId,
          start_date: startDate,
          start_end_days: this.startEndDays,
        }
      }).then((response) => {
        let availability = response.data;
        if (availability.length < this.startEndDays) {
          this.hideBtnNextDatesProf = true
        } else {
          this.hideBtnPreviousDatesProf = false;
        }

        this.professional.availability_dates = availability;
      });
    },
    previousPageProf() {
      let professional = this.professional;
      let availabilityDates = professional.availability_dates;
      let startAvailability = availabilityDates[0];

      if (this.currentStartDate) {
        this.currentStartDate = date(this.currentStartDate).add(-1, 'day').format('YYYY-MM-DD');
      } else {
        this.currentStartDate = date(startAvailability.available_date_patient).add(-1, 'day').format('YYYY-MM-DD');
      }

      http.get('/schedule/availabilities/dates-times', {
        params: {
          id_place: professional.id_place,
          id_prof: professional.id_prof,
          id_spec: this.specId,
          id_city: this.scheduleOnline ? null : this.cityId,
          start_date: this.currentStartDate,
          start_end_days: this.startEndDays,
        }
      }).then((response) => {
        let availability = response.data;
        let firstDate = availability[0].available_date_patient;
        if (date(firstDate) <= date())
          this.hideBtnPreviousDatesProf = true;

        this.hideBtnNextDatesProf = false
        this.professional.availability_dates = availability;
      });
    },
    getSpecialties() {
      return http.get(`/professionals/${this.professionalid}/specialties`).then((specialties) => this.specialties = specialties.data);
    },
  }
}
</script>

<style scoped>

</style>