export default class db {
    static toSchedule = {
        storePatient(patient) {
            sessionStorage.setItem('to_schedule_patient', JSON.stringify(patient));
            return patient;
        },
        get patient() {
            return JSON.parse(sessionStorage.getItem('to_schedule_patient'));
        },
        storeProfessional(professional) {
            sessionStorage.setItem('to_schedule_professional', JSON.stringify(professional));
            return professional;
        },
        get professional() {
            return JSON.parse(sessionStorage.getItem('to_schedule_professional'));
        },
        storeAvailabilityTime(availabilityTime) {
            sessionStorage.setItem('to_schedule_availability_time', JSON.stringify(availabilityTime));
            return availabilityTime;
        },
        get availabilityTime() {
            return JSON.parse(sessionStorage.getItem('to_schedule_availability_time'));
        },
        storeInsurance(insurance) {
            sessionStorage.setItem('to_schedule_insurance', JSON.stringify(insurance));
            return insurance;
        },
        get insurance() {
            return JSON.parse(sessionStorage.getItem('to_schedule_insurance'));
        },
        storePaymentMethod(paymentMethod) {
            sessionStorage.setItem('to_schedule_payment_method', JSON.stringify(paymentMethod));
            return paymentMethod;
        },
        get paymentMethod() {
            return JSON.parse(sessionStorage.getItem('to_schedule_payment_method'));
        },
        storeSpecialty(specialty) {
            sessionStorage.setItem('to_schedule_specialty', JSON.stringify(specialty));
            return specialty;
        },
        get specialty() {
            return JSON.parse(sessionStorage.getItem('to_schedule_specialty'));
        },
        storeCity(city) {
            sessionStorage.setItem('to_schedule_city', city ? JSON.stringify(city) : null);
            return city;
        },
        get city() {
            return JSON.parse(sessionStorage.getItem('to_schedule_city'));
        },
        storeCancelingPolicy(cancelingPolicy) {
            sessionStorage.setItem('to_schedule_canceling_policy', JSON.stringify(cancelingPolicy));
            return cancelingPolicy;
        },
        get cancelingPolicy() {
            return JSON.parse(sessionStorage.getItem('to_schedule_canceling_policy'));
        },
        storePixData(pixData) {
            sessionStorage.setItem('to_schedule_pix_data', JSON.stringify(pixData));
            return pixData;
        },
        get pixData() {
            return JSON.parse(sessionStorage.getItem('to_schedule_pix_data'));
        },
        storePlace(pixData) {
            sessionStorage.setItem('to_place', JSON.stringify(pixData));
            return pixData;
        },
        get place() {
            return JSON.parse(sessionStorage.getItem('to_place'));
        },
        storeScheduleType(type) {
            sessionStorage.setItem('to_schedule_type', type);
            return type;
        },
        get scheduleType() {
            return sessionStorage.getItem('to_schedule_type');
        },
        storeAttendanceType(type) {
            sessionStorage.setItem('to_schedule_attendance_type', type);
            return type;
        },
        get attendanceType() {
            return sessionStorage.getItem('to_schedule_attendance_type');
        },
    }

    static storeToken(token) {
        if (token == null || token === 'null') {
            localStorage.removeItem('token');
        } else {
            localStorage.setItem('token', token);
        }

        return token;
    }
    static get token() {
        let value = localStorage.getItem('token');
        if (value == null || value === 'null') {
            return null;
        }

        return value;
    }
    static storeUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
        return user;
    }
    static get user() {
        return JSON.parse(localStorage.getItem('user'));
    }
}