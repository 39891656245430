<template>
    <section class="section-two bg-light mt-5 mb-2">
        <div class="container-fluid">
            <section class="d-flex align-items-center">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="offset-lg-3 col-lg-6 col-md-6">
                            <div class="card login-page bg-white shadow rounded border-0">
                                <div class="card-body">

                                    <div class="text-center">
                                        <button type="button" class="btn btn-outline-primary btn-sm"
                                                @click="$router.back()">
                                            <i class="fa fa-arrow-left"></i> Voltar
                                        </button>
                                    </div>

                                    <h4 class="card-title text-center">Acesse sua conta</h4>
                                    <p class="text-center">Para continuar, inicie sua sessão com sua conta da
                                        Ubbimed.</p>
                                    <hr>

                                    <form class="login-form mt-4" @submit.prevent="checkRegister" method="POST">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label class="form-label" for="username">
                                                        CPF<span class="text-danger">*</span>
                                                    </label>
                                                    <div class="form-icon position-relative">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                             viewBox="0 0 24 24"
                                                             fill="none" stroke="currentColor" stroke-width="2"
                                                             stroke-linecap="round"
                                                             stroke-linejoin="round"
                                                             class="feather feather-user fea icon-sm icons">
                                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                            <circle cx="12" cy="7" r="4"></circle>
                                                        </svg>
                                                        <input type="tel" class="form-control ps-5"
                                                               placeholder="Informe seu CPF"
                                                               v-mask="'###.###.###-##'"
                                                               name="username" required="" v-model="username"
                                                               id="username">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12 mb-0">
                                                <div class="d-grid">
                                                    <button type="submit" :disabled="submitted" class="btn btn-primary">
                                                        Avançar
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </section>
</template>

<script>
import http from "../../helpers/http";
import {mask} from "vue-the-mask";

export default {
    name: 'PreLoginRegister',
    directives: {
        mask,
    },
    data() {
        return {
            username: '',
            submitted: false,
        }
    },
    created() {
        if (this.$store.getters['auth/isAuth'])
            this.$router.push('/schedule/patients');
    },
    methods: {
        checkRegister() {
            this.submitted = true;
            http.get(`/patients/has-register`, {params: {username: this.username}})
                .then((response) => {
                    let user = response.data;
                    if (user == null) {
                        return this.$router.push('/schedule/register');
                    }

                    if (user.user_password || user.user_reg_manual) {
                        this.$router.push({ path: '/schedule/login', query: { username: this.username } });
                    } else {
                        this.$router.push({ path: '/schedule/register', query: { username: this.username } });
                    }
                }).finally(() => {
                    this.submitted = false;
                });
        },
    }
}
</script>

<style scoped>

</style>