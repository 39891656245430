<template>
  <section class="section-two bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col-lg-3 mb-4 order-2 order-sm-1 order-md-1">
          <MenuAction/>
        </div>

        <div class="col-lg-9 order-1 order-sm-2 order-md-2">
          <div class="row mb-4">
              <p class="col-md-8 h4">
                  <b>Escolha o paciente</b>
              </p>
            <div class="col-md-4 text-sm-end">
                <div class="d-grid gap-2">
                  <button class="btn btn-outline-primary" @click="onClickBtnStore()">
                    Novo paciente
                  </button>
                </div>
            </div>
          </div>

          <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate"
               @click="selectPatient(patient)" title="Agendar para esse paciente"
               style="cursor: pointer;" v-for="patient in patients">
              <div class="ribbon ribbon-right ribbon-warning overflow-hidden" v-if="patient.main">
                  <span class="text-center d-block shadow small h6">Você</span>
              </div>
              <div class="row">
                  <div class="col-md-12 text-center">
                    <div class="m-3">
                      <span class="text-primary">
                        <b>{{ patient.patient_name }}</b>
                      </span>
                      <br><span class="text-primary">
                        {{ patient.patient_full_age }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12">
                      <div class="mx-3 mb-3 text-center">
                          <div class="d-grid gap-2 d-md-block">
                              <button type="button" class="btn btn-primary"
                                      @click="selectPatient(patient)" title="Agendar para esse paciente">
                                  Agendar <i class="ms-1 fas fa-1x fa-arrow-circle-right"></i>
                              </button>
                              <button type="button" class="btn btn-outline-primary ms-xl-2"
                                      @click.stop="onClickBtnEdit(patient)">
                                  Editar <i class="fa fa-edit"></i>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

        </div>
      </div>
    </div>

    <div class="modal fade" id="ModalNewPatient" tabindex="-1" aria-labelledby="ModalAddress" aria-hidden="true">
      <div class="modal-dialog">
        <form method="post" @submit.prevent="formPatient.id_patient ? updatePatient() : storePatient()" ref="formPatient">
          <div class="modal-content rounded shadow border-0">
            <div class="modal-header border-bottom">
              <h5 class="modal-title">{{formPatient.id_patient ? 'Editar' : 'Novo'}} paciente</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">

                  <div class="alert alert-success" role="alert" v-if="msgPatientSuccess">
                    {{msgPatientSuccess}}
                  </div>

                  <div class="alert alert-danger" role="alert" v-if="msgPatientError">
                    {{msgPatientError}}
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="patient_name">Nome <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="feather feather-user fea icon-sm icons">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                      <input name="patient_name" id="patient_name" type="text" v-model="formPatient.patient_name"
                             :class="errors.patient_name ? 'is-invalid' : ''" required
                             class="form-control ps-5" placeholder="Nome :">
                      <div class="invalid-feedback d-block" v-if="errors.patient_name">
                        {{ errors.patient_name.join('\n') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label" for="patient_cpf">CPF <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="feather feather-mail fea icon-sm icons">
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                      </svg>
                      <input name="patient_cpf" id="patient_cpf" type="tel" v-model="formPatient.patient_cpf"
                             :class="errors.patient_cpf ? 'is-invalid' : ''" v-mask="'###.###.###-##'"
                             class="form-control ps-5" placeholder="CPF :" required>
                      <div class="invalid-feedback d-block" v-if="errors.patient_cpf">
                        {{ errors.patient_cpf.join('\n') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label" for="patient_birthdate">Data Nascimento <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="feather feather-mail fea icon-sm icons">
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                      </svg>
                      <input name="patient_birthdate" id="patient_birthdate" type="tel"
                             v-model="formPatient.patient_birthdate" required
                             :class="errors.patient_birthdate ? 'is-invalid' : ''" v-mask="'##/##/####'"
                             class="form-control ps-5" placeholder="Data de nascimento :">
                      <div class="invalid-feedback d-block" v-if="errors.patient_birthdate">
                        {{ errors.patient_birthdate.join('\n') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label" for="observations">Observação</label>
                    <div class="form-icon position-relative">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle fea icon-sm icons"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                      <textarea name="observations" id="observations" rows="4"
                                :class="errors.observations ? 'is-invalid' : ''" v-model="formPatient.observations"
                                class="form-control ps-5" placeholder="Observação do paciente :"></textarea>
                      <div class="invalid-feedback d-block" v-if="errors.observations">
                        {{ errors.observations.join('\n') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12">
                    <div class="d-grid gap-2">
                      <button type="submit" :disabled="submitted" class="btn btn-outline-primary">
                        Salvar
                        <svg v-if="submitted" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" class="local-spin svg-inline--fa fa-spinner fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg>
                      </button>
                    </div>
                </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import MenuAction from './MenuAction.vue'
import http from "../../helpers/http";
import {mask} from 'vue-the-mask';
import Alert from "../../helpers/alert";
import Modal from "../../helpers/modal";
import Swal from "sweetalert2";
import date from "../../helpers/date";

export default {
  name: 'Patient',
  components: {
    MenuAction,
  },
  directives: {
    mask,
  },
  data() {
    return {
      search: '',
      submitted: false,
      formPatient: {
        patient_name: '',
        patient_cpf: '',
        patient_birthdate: '',
        observations: '',
      },
      errors: [],
      msgPatientSuccess: '',
      msgPatientError: '',
    }
  },
  computed: {
    patients() {
      let patients = this.$store.state.toSchedule.patients;
      if (this.search === '')
        return patients;

      return patients.filter(patient => {
        let strFilter = JSON.stringify(patient);
        return strFilter.toLowerCase().includes(this.search.toLowerCase())
      });
    },
    paymentMethod() {
      return this.$store.state.toSchedule.schedule.paymentMethod;
    },
      specialty() {
          return this.$store.state.toSchedule.schedule.specialty;
      },
      professional() {
          return this.$store.state.toSchedule.schedule.professional;
      },
  },
  mounted() {
    this.$store.dispatch('toSchedule/getPatients');
  },
  methods: {
      date: date,
      onClickBtnStore() {
          this.msgPatientSuccess = '';
          this.formPatient = {
              patient_name: '',
              patient_cpf: '',
              patient_birthdate: '',
              observations: '',
          };

          Modal.toggle('#ModalNewPatient');
      },
      onClickBtnEdit(patient) {
          this.msgPatientSuccess = '';
          this.formPatient = {
              id_patient: patient.id_patient,
              patient_name: patient.patient_name,
              patient_cpf: patient.patient_cpf,
              patient_birthdate: patient.patient_birthdate_br,
              observations: patient.observations,
          };

          Modal.toggle('#ModalNewPatient');
      },
      updatePatient() {
          this.submitted = true;
          let userAuth = this.$store.state.auth.user;
          this.errors = [];
          this.msgPatientError = '';
          http.put(`/users/${userAuth.id_user}/patients/${this.formPatient.id_patient}`, this.formPatient).then((_) => {
              this.msgPatientSuccess = 'Paciente atualizado com sucesso.';
              this.$store.dispatch('toSchedule/getPatients');
              this.formPatient = {
                  patient_name: '',
                  patient_cpf: '',
                  patient_birthdate: '',
                  observations: '',
              };

              Modal.toggle('#ModalNewPatient');
          }).catch((error) => {
              let response = error.response;
              if (response?.status === 422) {
                  this.errors = response.data.errors;
              } else {
                  this.msgPatientError = response.data;
              }
          }).finally(() => {
              this.submitted = false;
          });
      },
    selectPatient(patient) {
      if (!patient.patient_age) {
          Swal.fire({
              text: 'Qual sua data de nascimento?',
              html:
                  `<input type="date" id="patient_birthdate_swal" class="swal2-input" placeholder="DD/MM/YYYY">`,
              showCancelButton: true,
              confirmButtonText: 'Continuar',
              confirmButtonColor: '#780f5a',
              cancelButtonText: 'Fechar',
              focusConfirm: false,
              preConfirm: () => {
                  const date = document.getElementById('patient_birthdate_swal').value;
                  if (!date) {
                      Swal.showValidationMessage('Por favor, Informe a data de nascimento.');
                      return null;
                  }
                  return { date };
              }
          }).then((result) => {
              if (result.isConfirmed) {
                  const { date } = result.value;
                  let birthdate = this.date(date).format('DD/MM/YYYY')
                  let formPatient = {
                      id_patient: patient.id_patient,
                      patient_name: patient.patient_name,
                      patient_cpf: patient.patient_cpf,
                      patient_birthdate: birthdate,
                      observations: patient.observations,
                  };

                  let userAuth = this.$store.state.auth.user;
                  let url = `/users/${userAuth.id_user}/patients/${patient.id_patient}`;
                  http.put(url, formPatient).then((_) => {
                      this.checkAppointment(patient);
                  }).catch(() => {
                      Alert.error('Opss, falha ao salvar data de nascimento.');
                  });
              }
          });
      } else {
          this.checkAppointment(patient);
      }
    },
    checkAppointment(patient) {
        if ( (parseInt(patient.patient_age) < parseInt(this.$store.state.toSchedule.schedule.professional.min_patient_age??0)) || (parseInt(patient.patient_age) > parseInt(this.$store.state.toSchedule.schedule.professional.max_patient_age??999) ) ) {
            Alert.error('Desculpe, mas o profissional não atende pacientes dessa idade. \n Faixa etária permitida: ' + this.$store.state.toSchedule.schedule.professional.patient_age_desc);
            return;
        }

        let urlCheckDuplicateSchedules = `/schedule/appointment/check`;
        http.get(urlCheckDuplicateSchedules, {
            params: {
                patient_cpf: patient.patient_cpf,
                id_spec: this.specialty.id_spec,
                id_prof: this.professional.id_prof,
                id_place: this.professional.id_place,
            }
        }).then((response) => {
            if (response.data?.length > 0) {
                let tableInfo = '<table class="table table-bordered">';
                tableInfo += '<thead><tr><th>Data e hora</th><th>Profissional</th><th>Especialidade</th></tr></thead><tbody>';

                response.data.forEach(item => {
                    tableInfo += `<tr><td>${this.date(item.schedule_date_time).format("DD/MM/YYYY HH:mm")}</td><td>${item.prof_name}</td><td>${item.spec_name}</td></tr>`;
                });

                tableInfo += '</tbody></table>';

                let htmlInformations = `O CPF <b>${patient.patient_cpf} (${patient.patient_name})</b>`
                    .concat('<br>')
                    .concat(`Já possui um agendamento para esse profissional ou especialidade.`)
                    .concat('<br>Tem certeza que deseja agendar?')
                    .concat(tableInfo);

                Swal.fire({
                    html: htmlInformations,
                    icon: "warning",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonColor: "#780f5a",
                    confirmButtonText: "Sim, agendar",
                    cancelButtonText: "Não, revisar agendamentos",
                    width: '800px',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.selectPatientAndGoToNextStep(patient)
                    }
                });
            } else {
                this.selectPatientAndGoToNextStep(patient)
            }
        });
    },
    selectPatientAndGoToNextStep(patient) {
        this.$store.commit('toSchedule/selectPatient', patient);

        if (this.paymentMethod?.is_credit_card || this.paymentMethod?.is_pix) {
            this.$router.push('/canceling-policies');
        } else {
            this.$router.push('/payment');
        }
    },
    storePatient() {
      this.submitted = true;
      let userAuth = this.$store.state.auth.user;
      this.errors = [];
      this.msgPatientError = '';
      delete this.formPatient.id_patient;
      delete this.formPatient._method;
      http.post(`/users/${userAuth.id_user}/patients`, this.formPatient).then((_) => {
        this.msgPatientSuccess = 'Paciente cadastrado com sucesso.';
        this.$store.dispatch('toSchedule/getPatients');
        this.formPatient = {
          patient_name: '',
          patient_cpf: '',
          patient_birthdate: '',
          observations: '',
        };

        Modal.toggle('#ModalNewPatient');
      }).catch((error) => {
        let response = error.response;
        if (response?.status === 422) {
          this.errors = response.data.errors;
        } else {
          this.msgPatientError = response.data;
        }
      }).finally(() => {
        this.submitted = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
