import Swal from "sweetalert2";

export default class Alert {

    static success(message, title = null, timer = 1200) {
        Swal.fire({
            title: title || 'Aeeeh!',
            text: message,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#780f5a',
            timer: timer,
        })
    }

    static error(message, title = null) {
        Swal.fire({
            title: title || 'Opsss!',
            text: message,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#780f5a',
        })
    }
}